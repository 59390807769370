home-content{
    background-color: black;
}
.home-text{
    color: white;
    justify-content: left;
    margin-left: 10%;
    align-items: center;
    height: 400px;
    margin-top: 10%;
    letter-spacing: 1px;

}
.home-text-opening{
    font-size: 30px;
    margin-bottom: 15px;
}
.home-text-body{
    font-size: 20px;
}
.home-headshot{
    margin-top: -20%;
    margin-left: 50%;
    width: fit-content;
    height: fit-content;
}
.home-headshot-img{
    height: 520px;
}