.ucsb-content{
    display: flex;
}
.ucsb-text{
    color:white;
    letter-spacing: 1px;
    width: 350px;
    margin-top: 170px;
    margin-left: 110px;
    font-size: 20px;
}
.ucsb-images{
    height: auto;
    margin-left: 0;
    margin-top: 120px;
    margin: 20px;
}
.ucsb-image-bottom{
    display: flex;
}
.ucsb-homepage{
    margin-left: 100px;
    padding: 10px;
    margin-top: 20px;
}
.ucsb-post{
    margin-left:100px;
    padding: 10px;
}