.organized-video{
    border: 10px white solid;
    height:450px;
    width:800px;
    margin-top: 60px;
    margin-left:550px;
    z-index: -99999;
    position: absolute;
}
.organized-text{
    color: white;
    font-size: 20px;
    letter-spacing: 1px;
    width: 350px;
    height: fit-content;
    margin-top: 170px;
    margin-left: 100px;
    position: absolute;
}