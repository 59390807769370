.titlebar{
    height: 120px;
    background-color: black;
}
.titlebar-text{
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    display: flex;
    justify-content: center;   
    align-items: center; 
    height: 40px;
    font-size: 35px;
    letter-spacing: 15px;
}
.titlebar-options{
    border-bottom: 10px solid white;
    border-top: 10px solid white;
    background-color: black;
    text-align: center;
    font-size: 20px;
    list-style: none;
    overflow: hidden;
    letter-spacing: 10px;

}
.titlebar-home{
    box-sizing: border-box;
    float: left;
    margin: 0;    
    padding: 10px;
    width: 33%;
}
.titlebar-projects{
    box-sizing: border-box;
    float: left;
    margin: 0;    
    padding: 10px;
    width: 33%;
}
.titlebar-about{
    box-sizing: border-box;
    float: left;
    padding: 10px;
    width: 33%;
}
.titlebar-dropdown{
    background-color:black;
    border: 10px solid white;
    list-style: none;
    position: absolute;
    left: 50%;
    transform: translate(-45%,-30%);
    padding: 10px;
}
.titlebar-dropdown-container{
    text-align: center;
}
.titlebar-dropdown-li{
    color: white;
    padding: 10px;
}
.titlebar-link
{
    color: white;
    text-decoration: none;
}
