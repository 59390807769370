.about-body{
    color: white;
    font-size: 20px;
    margin-top: 13%;
    margin-left: 10%;
    letter-spacing: 1px;
}

.about-baby{
    border: 10px white solid;
    width: fit-content;
    height: fit-content;
    margin-top: -17%;
    margin-left: 55%;
}

.about-baby-img{
    height: 500px;
}