.Geopic-content{
    display:flex;
}

.Geopic-text{
    color:white;
    font-size: 20px;
    letter-spacing: 1px;
    width: 350px;
    margin-top: 200px;
    margin-left:100px;
    height: fit-content;
}
.Geopic-images{
    display: flex;
    width: fit-content;
    margin-top: 20px;
    margin-left: 50px;
}
.Geopic-login{
    padding: 20px;
}
.Geopic-post{
    padding:20px;
}
.Geopic-map{
    padding:20px;
}